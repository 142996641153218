html
  height: 100vh
  width: 100vw
  display: flex
  padding: 0
  margin: 0
  background: rgb(184, 238, 105)
  background: linear-gradient(90deg, rgba(184,238,105,1) 0%, rgba(0,103,88,1) 100%)

body
  margin: 0
  height: 100%
  width: 100%
  padding-top: 70px !important
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale