.fullscreenmodal
    padding: 0 !important
    margin: 0 !important
    width: 100vw
    height: 100vh

.modal-dialog
    width: 100vw
    max-width: none
    height: 100vh
    margin: 0

.modal-content
    border: 0
    border-radius: 0

.modal-card
    width: 95vw
    height: 95vh

.modal-body
    overflow-y: auto

.modal-background
    background: rgb(184, 238, 105)
    background: linear-gradient(90deg, rgba(184,238,105,1) 0%, rgba(0,103,88,1) 100%)
