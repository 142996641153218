@charset "utf-8"

@import '../../node_modules/bulma/sass/utilities/_all.sass'
@import '../../node_modules/bulma/sass/base/_all.sass'
@import '../../node_modules/bulma/sass/elements/button.sass'
@import '../../node_modules/bulma/sass/elements/container.sass'
@import '../../node_modules/bulma/sass/elements/title.sass'
@import '../../node_modules/bulma/sass/components/navbar.sass'
@import '../../node_modules/bulma/sass/grid/columns.sass'
@import '../../node_modules/bulma/sass/form/_all.sass'
@import '../../node_modules/bulma/sass/components/panel.sass'
@import '../../node_modules/bulma/sass/elements/tag.sass'
@import '../../node_modules/bulma/sass/helpers/spacing.sass'
@import '../../node_modules/bulma/sass/helpers/typography.sass'
@import '../../node_modules/bulma-extensions/bulma-tagsinput/dist/css/bulma-tagsinput.sass'
@import '../../node_modules/bulma/bulma.sass'
@import '../../node_modules/bulma/sass/components/card.sass'
$tooltip-arrow-size: 0px
@import '../../node_modules/bulma-tooltip/src/sass'

.no-bulletpoint
    list-style-type: none
    padding-left: 0
    margin-left: 0

.table-container
    overflow: visible
